<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="影院组织" name="organizationId">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
<!--							<a-select-option :value="''">全部</a-select-option>-->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
			
					<a-form-item class="ui-form__item" label="所属影院" name="cinemaIds">
						<a-select placeholder="请选择" v-model:value="formState.cinemaIds" style="min-width: 180px;max-width: 500px;" mode="multiple" @change="onCinemaChange">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id" > {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="销售时间" name="payTime" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-range-picker v-model:value="formState.payTime" @calendarChange="onCalendarChange" :disabledDate="disabledDate"/>
					</a-form-item>
				</a-row>
				
				<a-row v-show="showAll">
					<a-form-item name="orderNo" class="ui-form__item" label="订单号">
						<a-input v-model:value="formState.orderNo" placeholder="请输入订单号"></a-input>
					</a-form-item>
					
<!--					<a-form-item class="ui-form__item" label="订单来源" name="source">-->
<!--						<a-select v-model:value="formState.source" style="width: 190px;" placeholder="请选择订单来源">-->
<!--							<a-select-option :value="0">全部</a-select-option>-->
<!--							<a-select-option :value="1">微信小程序</a-select-option>-->
<!--							<a-select-option :value="2">抖音小程序</a-select-option>-->
<!--						</a-select>-->
<!--					</a-form-item>-->
					
					<a-form-item name="userPhone" class="ui-form__item" label="会员手机">
						<a-input v-model:value="formState.userPhone" placeholder="请输入会员手机号"></a-input>
					</a-form-item>
					
					<a-form-item name="goodsName" class="ui-form__item" label="影片名称">
						<a-input v-model:value="formState.goodsName" placeholder="请输入影片名称"></a-input>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="取票状态" name="ticketStatus">
						<a-select style="width: 190px;" placeholder="选择取票状态" v-model:value="formState.ticketStatus">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="1">未取票</a-select-option>
							<a-select-option :value="3">已取票</a-select-option>
							<a-select-option :value="4">已退票</a-select-option>
							<a-select-option :value="5">未退票</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="放映时间">
						<a-range-picker v-model:value="showTime"/>
					</a-form-item>
				</a-row>
				
				<a-row>
					<a-col :span="18">
			            <span v-permission="['report_revenue_paySale_import']">
							<ExportReport type="cashTicketDetailStatistical" :searchData="searchData"></ExportReport>
						</span>
						<a style="margin-left: 20px;" @click="showAll = !showAll">
							{{ showAll ? '收起' :'展开' }}
							<Icon v-show="!showAll" icon="DownOutlined"></Icon>
							<Icon v-show="showAll" icon="UpOutlined"></Icon>
						</a>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			
			<a-table style="margin-top: 10px;" rowKey="id" :columns="columns" :dataSource="list" :pagination="pagination" :scroll="{ x: 2600 }">
				<template #bodyCell="{column, record}">
					<template v-if="column.key === 'date'">
						{{ transDateTime(record.createTime , 1) }}
					</template>
					
					<template v-if="column.key === 'source'">
						<div>
							<a-tag color="green" v-if="record.source === 1">
								微信小程序
							</a-tag>
							<a-tag color="blue" v-else> 抖音小程序 </a-tag>
						</div>
					</template>
					
					<template v-if="column.key === 'week'">
						星期{{ ['日', '一', '二', '三', '四', '五', '六'][moment(record.createTime *1000).day()] }}
					</template>
					
					<template v-if="column.key === 'showTime'">
						<div>{{ transDateTime(record.showStartTime) }}</div>
						至
						<div>{{ transDateTime(record.showEndTime) }}</div>
					</template>
					
					<template v-if="column.key === 'seat'">
						<span v-for="(item, i) in record.cinemaSeatsJsonVOS" :key="i">
							<a-tag style="margin-bottom: 6px;" color="blue" v-if="item">
								{{ item.rowId }}排 {{ item.colId }}座
							</a-tag>
						</span>
					</template>
					
					<template v-if="column.key === 'refundStatus'">
						<div v-if="record.status === 'AFTERSALE_FINISH'">已退票</div>
						<div v-else-if="record.ticketStatus === 1">未取票</div>
						<div v-else-if="record.ticketStatus === 3">已取票</div>
					</template>
					
					<template v-if="column.key === 'userInfo'">
						<div>用户昵称：{{ record.userName || '--' }}</div>
						<div>用户手机：{{ record.userPhone || '--' }}</div>
					</template>
				</template>
				
				<template #summary v-if="list && list.length">
					<a-table-summary-row>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell>
							合计：{{ total.seatsNum || 0 }}
						</a-table-summary-cell>
						<a-table-summary-cell>
							合计：￥{{ total.totalFee || 0 }}
						</a-table-summary-cell>
						<a-table-summary-cell>
							合计：￥{{ total.distributionPrice || 0 }}
						</a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell>
							合计：￥{{ total.refund || 0 }}
						</a-table-summary-cell>
						<a-table-summary-cell>
							合计：￥{{ total.totalServiceFee || 0 }}
						</a-table-summary-cell>
						<a-table-summary-cell>
							合计：{{ total.realSeatsNum || 0 }}
						</a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
					</a-table-summary-row>
				</template>
			</a-table>
		</a-spin>
	</div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	import ExportReport from '@/components/exportReport/exportReport.vue';
	import { cashTicketDetailStatistical } from '@/service/modules/report.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	export default {
		components: {Icon, ExportReport},
		data(){
			return {
				loading: false,
				showAll: false,
				organizationList: [],
				cinemaAllList: [],
				//payTime: [],
				showTime: [],
				formState: {
					organizationId: '',
					cinemaIds: [0],
					source: 0,
					ticketStatus: '',
					payTime: []
				},
				searchData: {},
				list:[],
				columns: [{
					title: '日期',
					key: 'date'
				}, {
					title: '星期',
					key: 'week'
				}, {
					title: '影院名称',
					dataIndex: 'cinemaName'
				}, {
					title: '订单号',
					dataIndex: 'orderNo'
				}, {
					title: '订单来源',
					key: 'source',
					width: 120,
					align: 'center'
				}, {
					title: '影片名称',
					dataIndex:'filmName'
				}, {
					title: '放映时间',
					key: 'showTime'
				}, {
					title: '座位号',
					key: 'seat'
				}, {
					title: '座位价格',
					dataIndex: 'lowestPrice'
				}, {
					title: '销售价格',
					dataIndex: 'seatsPrice'
				}, {
					title: '座位数量',
					dataIndex: 'seatsNum'
				}, {
					title: '销售合计',
					dataIndex: 'totalFee'
				}, {
					title: '分销金额',
					dataIndex: 'distributionPrice'
				}, {
					title: '取票状态',
					key: 'refundStatus'
				}, {
					title: '退票金额',
					dataIndex: 'refund'
				}, {
					title: '退票服务费',
					dataIndex: 'totalServiceFee'
				}, {
					title: '实际座位数量',
					dataIndex: 'realSeatsNum'
				}, {
					title: '会员信息',
					key: 'userInfo'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				total: {}
			}
		},
		created(){
			this.formState.payTime = [this.moment().subtract(7, 'day').startOf('day'), this.moment().endOf('day')];
			this.onSearch();
			this.getOrganizationList();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.source = this.searchData.source ? this.searchData.source : undefined;
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				if(this.searchData.cinemaIds && this.searchData.cinemaIds.length && this.searchData.cinemaIds.indexOf(0) === -1) {
					this.searchData.cinemaIds = this.searchData.cinemaIds.join(',');
				} else {
					this.searchData.cinemaIds = undefined;
				}
				if (this.formState.payTime && this.formState.payTime.length) {
					this.searchData.startTime = this.moment(this.formState.payTime[0].startOf('day')).unix();
					this.searchData.endTime = this.moment(this.formState.payTime[1].endOf('day')).unix();
				}
				if (this.showTime && this.showTime.length) {
					this.searchData.showStartTime = this.moment(this.showTime[0].startOf('day')).unix();
					this.searchData.showEndTime = this.moment(this.showTime[1].endOf('day')).unix();
				}
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.searchData = {};
				this.formState.cinemaIds = [0];
				this.formState.payTime = [this.moment().subtract(7, 'day').startOf('day'), this.moment().endOf('day')];
				this.showTime = [];
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.pagination.total = 0;
				this.list = [];
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await cashTicketDetailStatistical({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.total = ret.data.list[0];
						this.list = ret.data.list.splice(1);
					}
				} catch (error) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onCinemaChange(keys) {
				if (keys.indexOf(0) !== -1) {
					keys.splice(keys.indexOf(0), 1);
				}
				if (keys.length === 0) {
					keys = [0];
				}
				this.formState.cinemaIds = keys;
			},
			disabledDate(current) {
				if (!this.formState.payTime || this.formState.payTime.length === 0) {
				    return current && current > this.moment().endOf('day');
				}
				const tooLate = this.formState.payTime[0] && current.diff(this.formState.payTime[0], 'days') > 31;
				const tooEarly = this.formState.payTime[1] && this.formState.payTime[1].diff(current, 'days') > 31;
				const tooCurrent = current && current > this.moment().endOf('day');
				return tooEarly || tooLate || tooCurrent;
			   // return current && current > this.moment().endOf('day');
			},
			onCalendarChange(val) {
				this.formState.payTime = val;
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
